<template>
  <div class="content" no-scroll>
    <div class="box">
      <div style="display: flex; align-items: center;justify-content: space-between">
        <div style="display: flex; align-items: center">
          <Breadcrumb></Breadcrumb>
          <div style="font-size: 14px; color: #3e3e3e">
            <span style="margin: 0 5px; color: #c0c4cc">/</span>
            {{ courseInfo.course_name }}
          </div>
        </div>
        <div v-if="courseInfo.is_plan!=1 && userInfo.id>0">
          <el-button size="small" v-if="courseInfo.learn_mode==2 && courseInfo.isTeach"  @click="goApplyManage" type="primary" icon="el-icon-monitor">报名管理</el-button>
          <el-button size="small" v-if="courseInfo.learn_mode==2  && courseInfo.isTeach" @click="goAttendance" type="primary" icon="el-icon-printer">出勤</el-button>
          <el-button size="small" v-if="courseInfo.isTeach" type="primary" @click="goWork" icon="el-icon-edit-outline">作业</el-button>
          <el-button size="small" type="primary" v-if="courseInfo.isTeach ||courseInfo.isStudent"  @click="goStuSituation" icon="el-icon-data-line">学情</el-button>
          <el-button size="small" v-if="courseInfo.learn_mode==2 && courseInfo.isTeach" @click="goSchedule" type="primary" icon="el-icon-finished">进度</el-button>
          <el-button size="small" type="primary" @click="goHome" icon="el-icon-house">班级主页</el-button>
        </div>
      </div>
      <div style="display: flex">
        <div style="flex: 1;width: 60%;">
          <!-- <el-row :gutter="10"> -->
          <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"> -->
          <el-card v-loading="loading">
            <div :style="courseInfo.is_plan === 1 ? '' : 'border-radius: 8px;'">
              <div class="video" style="width: 100%; height: 30rem">
                <img
                  v-if="
                    (currentInfo.video ||
                      currentInfo.pdf ||
                      courseInfo.video ||
                      '') === ''
                  "
                  :src="courseInfo.cover"
                  fit="contain"
                  style="width: 100%; height: 100%"
                />
                <div
                  v-else-if="currentInfo.isPdf === false"
                  style="width: 100%; height: 100%"
                >
                  <video-player
                    :reloadUrl="currentInfo.video || courseInfo.video"
                    id="video1"
                  ></video-player>
                </div>
                <el-pdf
                  name="el-pdf"
                  ref="pdf"
                  :src="currentInfo.pdf"
                  :page="currentInfo.pdfCurrent"
                  class="PDF"
                  v-else
                  style="width: 100%; height: 100%"
                ></el-pdf>
              </div>
            </div>
            <div>
              <div style="line-height: 30px">
                <!-- <div class="speaker" v-if="courseInfo.is_plan === 1">
                  创建人：{{
                    courseInfo.teach_name || courseInfo.lecturer_name
                  }}
                </div> -->

                <div
                  class="courseName"
                  style="font-size: 18px; font-weight: bold;margin-top: 14px;"
                >
                  <div>{{ courseInfo.course_name }}</div>
                </div>

                <!-- <div class="speaker" v-if="courseInfo.is_plan !== 1">
                班主任及助教：{{
                  ((courseInfo.lecturers || {}).data || [])
                    .filter(
                      (d) =>
                        d.id != courseInfo.lecturer_id ||
                        d.id == courseInfo.user_id
                    )
                    .map((d) => d.nickname || d.realname)
                    .join("、")
                }}
              </div> -->
                <div
                  style="display: flex; align-items: center; font-size: 14px"
                >
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      margin-right: 20px;
                    "
                    v-if="courseInfo.is_plan !== 1"
                  >
                    <span style="color: #363636"
                      >学习：{{ courseInfo.student_count || 0 }}</span
                    >
                  </div>

                  <div class="operation" style="display: flex">
                    <div
                      @click="
                        handleCommand(
                          'collect',
                          courseInfo.is_plan == 1 ? 5 : 1,
                          courseInfo.id,
                          'courseInfo',
                          !courseInfo.is_collect
                        )
                      "
                      data-type="4"
                      style="cursor: pointer; margin-right: 20px"
                      v-if="courseInfo.user_id + '' !== userInfo.id + ''"
                    >
                      <i
                        class="el-icon-star-off icon"
                        v-if="!courseInfo.is_collect"
                      ></i>
                      <i
                        class="el-icon-star-on icon"
                        v-if="courseInfo.is_collect"
                        style="color: #f56c6c"
                      ></i>
                      <span>收藏</span>
                    </div>
                  </div>
                  <div
                    class="hour"
                    style="margin-right: 20px"
                    v-if="courseInfo.is_plan !== 1"
                  >
                    <div>课时：{{ courseInfo.class_hour }}</div>
                  </div>
                  <div class="hour" style="margin-right: 20px" v-else>
                    <div>条目数：{{ courseInfo.class_hour }}</div>
                  </div>
                  <div class="price" v-if="courseInfo.price > 0 && !courseInfo.is_plan">
                    价格：<span style="color: #f00606"
                      >¥{{ courseInfo.price }}</span
                    >
                  </div>
                  <div class="price" v-else-if="courseInfo.price == 0 && !courseInfo.is_plan">
                    价格：<span style="color: #f00606">免费</span>
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    width: 100%;
                    line-height: 40px;
                    text-align: right;
                  "
                >
                  <template v-if="courseInfo.is_plan === 1">
                    <div
                      class="price"
                      v-if="courseInfo.order && courseInfo.buyProgress > 0"
                    >
                      <div
                        style="display: flex; justify-content: space-between"
                      >
                        普通价格：<span style="color: #f00606"
                          >¥{{ courseInfo.price }}</span
                        >
                      </div>
                      <div
                        style="display: flex; justify-content: space-between"
                        class="price"
                        v-if="courseInfo.price2 > 0"
                      >
                        高级价格：<span style="color: #f00606"
                          >¥{{ courseInfo.price2 }}</span
                        >
                      </div>
                      <div
                        v-if="
                          courseInfo.order &&
                          courseInfo.order.params.type == 'apply'
                        "
                      >
                        优惠{{
                          courseInfo.order.params.buy_type == 1
                            ? "普通教案"
                            : "高级教案"
                        }}价格：<span style="color: #f00606"
                          >¥{{ courseInfo.order.params.price }}</span
                        >
                      </div>
                    </div>
                    <div v-else>
                      <div class="price" v-if="courseInfo.price > 0">
                        普通价格：<span style="color: #f00606"
                          >¥{{ courseInfo.price }}</span
                        >
                      </div>
                      <div class="price" v-if="courseInfo.price2 > 0">
                        高级价格：<span style="color: #f00606"
                          >¥{{ courseInfo.price2 }}</span
                        >
                      </div>
                      <div
                        class="price"
                        v-else-if="
                          courseInfo.price2 == 0 && courseInfo.price == 0
                        "
                      >
                        价格：<span style="color: #f00606">免费</span>
                      </div>
                    </div>

                    <div class="operate" v-if="courseInfo.isExist">
                      <p v-if="courseInfo.isExist2">[高级许可使用]已购买</p>
                      <p v-else-if="courseInfo.isExist1">
                        [普通许可使用]已购买
                      </p>

                      <el-button
                        size="small"
                        type="primary"
                        @click="
                          $handleRoute(
                            { course_id: courseInfo.pid },
                            'teachPlanCourseDetail'
                          )
                        "
                        v-if="courseInfo.pid && courseInfo.pre_is_plan === 1"
                      >
                        查看原教案
                      </el-button>
                    </div>
                    <div
                      class="operate"
                      v-else-if="
                        (courseInfo.order || {}).progress < 100 &&
                        (courseInfo.order || {}).progress > 0
                      "
                    >
                      <div
                        v-if="
                          courseInfo.order.status == 1 &&
                          courseInfo.buy_apply_status == -1
                        "
                      >
                        <el-button
                          size="small"
                          type="danger"
                          @click="
                            $handleConfirm('您是否确认取消支付该教案', {
                              title: '取消购买',
                              callback: (action) => {
                                action === 'confirm' && handleCommand('cancel');
                              },
                            })
                          "
                          >取消支付</el-button
                        >
                        <el-button
                          size="small"
                          type="primary"
                          @click="
                            $handleRoute(
                              { order_id: courseInfo.order.id },
                              'teachPlanCourseDetailOrderSure'
                            )
                          "
                          >立即支付</el-button
                        >
                      </div>
                      <el-button
                        size="small"
                        type="primary"
                        @click="teachPlanCourseBuy1"
                        v-if="
                          (courseInfo.buy_apply_status == 0 ||
                            courseInfo.buy_apply_status == 1 ||
                            courseInfo.buy_apply_status == 2) &&
                          courseInfo.is_up == 1
                        "
                      >
                        购买
                      </el-button>
                      <el-button
                        size="small"
                        type="primary"
                        v-if="
                          (courseInfo.buy_apply_status == 0 ||
                            courseInfo.buy_apply_status == 1 ||
                            courseInfo.buy_apply_status == 2) &&
                          courseInfo.is_up == 1
                        "
                        :disabled="courseInfo.buy_apply_status == 0"
                        @click="
                          $handleRoute(
                            { order_id: courseInfo.order.id },
                            'teachPlanCourseDetailOrderSure'
                          )
                        "
                        >以优惠价格购买</el-button
                      >

                      <p
                        style="font-size: 12px"
                        v-if="courseInfo.buy_apply_status == 1"
                      >
                        您申请的优惠，所有者已经反馈，可以以优惠价格<span
                          style="color: red"
                          >￥{{ courseInfo.order.params.price }}</span
                        >
                        购买{{
                          courseInfo.order.params.buy_type == 1
                            ? "普通教案"
                            : "高级教案"
                        }}
                      </p>

                      <p
                        style="font-size: 12px"
                        v-if="courseInfo.buy_apply_status == 0"
                      >
                        您申请的优惠已提交，等待所有者反馈
                      </p>
                    </div>
                    <div v-else>
                      <div
                        style="margin-top: 30px"
                        v-if="
                          (courseInfo.isExist1 || !courseInfo.isExist2) &&
                          courseInfo.is_up == 1
                        "
                      >
                        <el-button
                          :disabled="!teachPlanCourseBuy(true)"
                          size="small"
                          type="primary"
                          @click="teachPlanCourseBuy"
                        >
                          购买
                        </el-button>
                        <el-button
                          size="small"
                          type="primary"
                          :disabled="!teachPlanCourseApplyBuy(true)"
                          v-if="
                            courseInfo.price > 0 ||
                            courseInfo.price2 > 0 ||
                            courseInfo.buy_apply_status == -1 ||
                            courseInfo.buy_apply_status == 2
                          "
                          @click="teachPlanCourseApplyBuy"
                        >
                          申请优惠
                        </el-button>
                      </div>
                      <p
                        style="font-size: 12px; color: red"
                        v-if="
                          courseInfo.last_order_apply &&
                          courseInfo.last_order_apply.status == 2 &&
                          courseInfo.buy_apply_status == -1
                        "
                      >
                        您的申请已被驳回
                      </p>
                      <p v-if="courseInfo.isExist2">[高级许可使用]已购买</p>
                      <p v-else-if="courseInfo.isExist1">
                        [普通许可使用]已购买
                      </p>
                    </div>
                  </template>
                  <template v-else>
                    <!-- <div class="price" v-if="courseInfo.price > 0">
                      价格：<span style="color: #f00606"
                        >¥{{ courseInfo.price }}</span
                      >
                    </div>
                    <div class="price" v-else>
                      价格：<span style="color: #f00606">免费</span>
                    </div> -->
                    <div style="font-size: 14px; display: flex">
                      <div>
                        <p v-if="courseInfo.enroll_activ_time">
                          报名时间：{{ courseInfo.enroll_activ_time }}
                        </p>
                        <p v-else-if="courseInfo.enroll_status === -1">
                          报名时间已截止
                        </p>
                        <p v-else-if="courseInfo.enroll_status === 0">
                          报名时间未开始
                        </p>
                      </div>
                      <div style="margin-left: 20px">
                        <p v-if="courseInfo.course_activ_time">
                          学习时间：{{ courseInfo.course_activ_time }}
                        </p>
                        <p v-else-if="courseInfo.course_status === -1">
                          学习时间已截止
                        </p>
                        <p v-else-if="courseInfo.course_status === 0">
                          学习时间未开始
                        </p>
                      </div>
                    </div>
                    <div
                      class="operate"
                      v-if="
                        (courseInfo.isStudent || courseInfo.isTeach) &&
                        (courseInfo.order || {}).id > 0
                      "
                    >
                      <el-button
                        size="small"
                        type="danger"
                        @click="
                          $handleConfirm('您是否确认取消支付该课程', {
                            title: '取消购买',
                            callback: (action) => {
                              action === 'confirm' && handleCommand('cancel');
                            },
                          })
                        "
                        >取消支付</el-button
                      >
                      <el-button
                        size="small"
                        type="primary"
                        @click="
                          $handleRoute(
                            { order_id: courseInfo.order.id },
                            courseInfo.order.type == 5
                              ? 'teachingCourseDetailOrderSure'
                              : 'courseDetailOrderSure'
                          )
                        "
                        >立即支付</el-button
                      >
                    </div>
                    <div v-else>
                      <div class="operate">
                        <el-button
                          :disabled="
                            (courseInfo.isStudent &&
                              (courseInfo.is_active !== 1 ||
                                courseInfo.is_up !== 1)) ||
                            (courseInfo.course_status !== 1 &&
                              !(
                                courseInfo.is_zy == 1 &&
                                courseInfo.course_status == 0
                              ))
                          "
                          size="small"
                          type="primary"
                          @click="handleCommand('study')"
                          v-if="courseInfo.isStudent"
                          >立即学习</el-button
                        >
                        <el-button
                          size="small"
                          type="primary"
                          @click="handleCommand('study')"
                          v-else-if="courseInfo.isTeach"
                        >
                          查看课程</el-button
                        >
                        <template
                          v-else-if="
                            !courseInfo.isStudent && courseInfo.studentApply && courseInfo.studentApply.apply_status==0 
                          "
                        >
                          <el-button
                            size="small"
                            @click="
                              $refs[courseInfo.studentApply.apply_type+'Dialog'].visibled(
                                (courseInfo.studentApply = {
                                  ...courseInfo.studentApply,
                                  status: 2,
                                }) && true
                              )
                            "
                            type="danger"
                            >拒绝加入</el-button
                          >
                          <el-button
                            size="small"
                            @click="
                              $refs[courseInfo.studentApply.apply_type+'Dialog'].visibled(
                                (courseInfo.studentApply = {
                                  ...courseInfo.studentApply,
                                  status: 1,
                                }) && true
                              )
                            "
                            type="primary"
                            >确认加入</el-button
                          >

                          <diy-dialog
                            ref="CourseWhiteDialog"
                            :title="
                              courseInfo.studentApply.status === 1
                                ? '通过'
                                : '拒绝'
                            "
                            center
                          >
                            <form-create
                              v-model="courseInfo.studentApply"
                              :components="[
                                {
                                  name: 'form-item',
                                  props: {
                                    prop: 'apply_group_name',
                                    disabled: true,
                                    label: '课程名称',
                                  },
                                },
                                {
                                  name: 'form-item',
                                  props: {
                                    prop: 'apply_reason',
                                    disabled: true,
                                    label: '申请理由',
                                  },
                                },
                                {
                                  name: 'form-item',
                                  props: {
                                    prop: 'apply_create_date',
                                    disabled: true,
                                    label: '申请时间',
                                    type: 'datetime',
                                  },
                                },
                                {
                                  name: 'form-item',
                                  props: {
                                    prop: 'reason',
                                    label: '驳回理由',
                                    type: 'textarea',
                                  },
                                  hidden: courseInfo.studentApply.status !== 2,
                                },
                              ]"
                              :label-width="'100px'"
                            >
                            </form-create>
                            <div slot="footer">
                              <el-button
                                style="margin-right: 50px"
                                type="primary"
                                @click="$refs.CourseWhiteDialog.visibled(false)"
                                >取 消</el-button
                              >
                              <el-button
                                type="primary"
                                @click="
                                  handleCommand(
                                    'CourseWhite',
                                    courseInfo.studentApply,
                                    courseInfo.studentApply.status,
                                    () => {
                                      $refs.CourseWhiteDialog.visibled(false);
                                      getNochapterDetails()
                                    }
                                  )
                                "
                                >确 定</el-button
                              >
                            </div>
                          </diy-dialog>
                          <diy-dialog
                            ref="CourseBaseUserDialog"
                            :title="
                              courseInfo.studentApply.status === 1
                                ? '通过'
                                : '拒绝'
                            "
                            center
                          >
                            <form-create
                              v-model="courseInfo.studentApply"
                              :components="[
                                {
                                  name: 'form-item',
                                  props: {
                                    prop: 'apply_group_name',
                                    disabled: true,
                                    label: '课程名称',
                                  },
                                },
                                {
                                  name: 'form-item',
                                  props: {
                                    prop: 'apply_reason',
                                    disabled: true,
                                    label: '申请理由',
                                  },
                                },
                                {
                                  name: 'form-item',
                                  props: {
                                    prop: 'apply_create_date',
                                    disabled: true,
                                    label: '申请时间',
                                    type: 'datetime',
                                  },
                                },
                                {
                                  name: 'form-item',
                                  props: {
                                    prop: 'reason',
                                    label: '驳回理由',
                                    type: 'textarea',
                                  },
                                  hidden: courseInfo.studentApply.status !== 2,
                                },
                              ]"
                              :label-width="'100px'"
                            >
                            </form-create>
                            <div slot="footer">
                              <el-button
                                style="margin-right: 50px"
                                type="primary"
                                @click="$refs.CourseBaseUserDialog.visibled(false)"
                                >取 消</el-button
                              >
                              <el-button
                                type="primary"
                                @click="
                                  handleCommand(
                                    'CourseBaseUser',
                                    courseInfo.studentApply,
                                    courseInfo.studentApply.status,
                                    () => {
                                      $refs.CourseBaseUserDialog.visibled(false);
                                      getNochapterDetails()

                                    }
                                  )
                                "
                                >确 定</el-button
                              >
                            </div>
                          </diy-dialog>
                        </template>
                        <el-button
                          size="small"
                          :disabled="
                            !(
                              courseInfo.is_just_need_pay ||
                              courseInfo.isTeach ||
                              courseInfo.isStudent === true ||
                              (courseInfo.enroll_activ_time &&
                                courseInfo.enroll_status === 1)
                            ) || courseInfo.is_up !== 1
                          "
                          type="primary"
                          :loading="studyLoading"
                          @click="handleCommand('buy', courseInfo.price, 2)"
                          v-else
                          >立即报名</el-button
                        >
                        <el-button
                          size="small"
                          type="primary"
                          @click="
                            courseInfo.active_price > 0
                              ? $refs.dialog.visibled(
                                  ((gForm = {
                                    active_price: courseInfo.active_price,
                                    gtype: 'apply',
                                  }),
                                  true)
                                )
                              : handleCommand(
                                  'buy',
                                  {
                                    price: courseInfo.active_price,
                                    gtype: null,
                                  },
                                  24
                                )
                          "
                          :disabled="
                            !$isPowers('courseActive') ||
                            courseInfo.notActive === true
                          "
                          v-if="
                            courseInfo.isTeach &&
                            courseInfo.is_free != 0 &&
                            (courseInfo.powers || {}).is_open !== false
                          "
                          >开通课程</el-button
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </el-card>
          <!-- </el-col> -->
          <!-- <el-row style="" :gutter="0"> -->
          <!-- <el-col
              v-if="courseInfo.is_plan !== 1"
              :lg="24"
              :md="24"
              :sm="24"
              style="margin-top: 20px"
            > -->
          <div style="margin-top: 20px" v-if="courseInfo.is_plan!=1">
            <div
              style="display: flex; justify-content: space-between"
              v-if="courseInfo.learn_mode === 2"
            >
              <el-card
                class="recommend"
                style="margin-bottom: 20px; width: 49%"
              >
                <template slot="header">
                  <span>班主任及助教</span>
                  <el-button
                    @click="$handleRoute('courseDetailTeach')"
                    style="float: right; padding: 3px 0"
                    type="text"
                    >查看更多<i class="el-icon-arrow-right"></i
                  ></el-button>
                </template>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  v-if="((courseInfo.lecturers || {}).data || []).length === 0"
                >
                  暂无
                </div>
                <el-row justify="center">
                  <el-col
                    :span="6"
                    v-for="(lecturer, lecturerIndex) in (
                      (courseInfo.lecturers || {}).data || []
                    ).slice(0, 4)"
                    :key="lecturerIndex"
                  >
                    <div class="student-item">
                      <el-image
                        style="width: 40px; height: 40px; border-radius: 50%"
                        :src="lecturer.headimg"
                        fit="cover"
                      ></el-image>

                      <el-tooltip
                        effect="light"
                        :content="lecturer.nickname"
                        placement="top"
                        v-if="lecturer.nickname.length > 9"
                      >
                        <span class="name">{{
                          lecturer.nickname.substring(0, 9) + "..."
                        }}</span>
                      </el-tooltip>
                      <div class="name" v-else>{{ lecturer.nickname }}</div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>

              <el-card
                class="recommend"
                style="margin-bottom: 20px; width: 49%"
              >
                <template slot="header">
                  <span>学员</span>
                  <el-button
                    @click="$handleRoute('courseDetailStudent')"
                    style="float: right; padding: 3px 0"
                    type="text"
                    >查看更多<i class="el-icon-arrow-right"></i
                  ></el-button>
                </template>

                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  v-if="((courseInfo.students || {}).data || []).length === 0"
                >
                  暂无
                </div>
                <el-row justify="center">
                  <el-col
                    :span="6"
                    v-for="(v, i) in (
                      (courseInfo.students || {}).data || []
                    ).slice(0, 4)"
                    :key="i"
                  >
                    <div class="student-item">
                      <el-image
                        style="width: 40px; height: 40px; border-radius: 50%"
                        :src="v.headimg"
                        fit="cover"
                      ></el-image>

                      <el-tooltip
                        effect="light"
                        :content="v.showname"
                        placement="top"
                        v-if="v.showname.length > 9"
                      >
                        <span class="name">{{
                          v.showname.substring(0, 9) + "..."
                        }}</span>
                      </el-tooltip>
                      <div class="name" v-else>{{ v.showname }}</div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </div>

            <div style="display: flex; justify-content: space-between" v-else>
              <el-card
                class="recommend"
                style="margin-bottom: 20px; width: 49%"
              >
                <template slot="header">
                  <span>主讲老师</span>
                  <el-button
                    @click="$handleRoute('courseDetailTeach')"
                    style="float: right; padding: 3px 0"
                    type="text"
                    >查看更多<i class="el-icon-arrow-right"></i
                  ></el-button>
                </template>

                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  v-if="((courseInfo.lecturers || {}).data || []).length === 0"
                >
                  暂无
                </div>
                <el-row justify="center">
                  <el-col
                    :span="6"
                    v-for="(lecturer, lecturerIndex) in (
                      (courseInfo.lecturers || {}).data || []
                    ).slice(0, 4)"
                    :key="lecturerIndex"
                  >
                    <div class="student-item">
                      <el-avatar
                        style="width: 40px; height: 40px"
                        :src="lecturer.headimg"
                      ></el-avatar>

                      <el-tooltip
                        effect="light"
                        :content="lecturer.nickname"
                        placement="top"
                        v-if="lecturer.nickname.length > 9"
                      >
                        <span class="name">{{
                          lecturer.nickname.substring(0, 9) + "..."
                        }}</span>
                      </el-tooltip>
                      <div class="name" v-else>{{ lecturer.nickname }}</div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
              <el-card
                class="recommend"
                style="margin-bottom: 20px; width: 49%"
              >
                <template slot="header">
                  <span>学员</span>
                  <el-button
                    @click="$handleRoute('courseDetailStudent')"
                    style="float: right; padding: 3px 0"
                    type="text"
                    >查看更多<i class="el-icon-arrow-right"></i
                  ></el-button>
                </template>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  v-if="((courseInfo.students || {}).data || []).length === 0"
                >
                  暂无
                </div>
                <el-row justify="center">
                  <el-col
                    :span="6"
                    v-for="(v, i) in (
                      (courseInfo.students || {}).data || []
                    ).slice(0, 4)"
                    :key="i"
                  >
                    <div class="student-item">
                      <el-avatar
                        style="width: 40px; height: 40px"
                        :src="v.headimg"
                      ></el-avatar>

                      <el-tooltip
                        effect="light"
                        :content="v.showname"
                        placement="top"
                        v-if="v.showname.length > 9"
                      >
                        <span class="name">{{
                          v.showname.substring(0, 9) + "..."
                        }}</span>
                      </el-tooltip>
                      <div class="name" v-else>{{ v.showname }}</div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </div>
          </div>
          <!-- </el-col> -->
          <!-- </el-row> -->
          <!-- <el-row style="" :gutter="0"> -->
          <!-- <el-col :lg="24" :md="24" :sm="24" style="margin-top: 20px"> -->
          <el-route-tabs
          
            class="el-tabs--menu"
            type="border-card"
            v-model="activeName"
            style="margin-top: 20px" 
            :tab-list="
              [
                {
                  label: (courseInfo.is_plan == 1 ? '教案' : '课程') + '简介',
                  name: 'description',
                },
              ].concat(tabList)
            "
            @tab-click="
              $handleRoute(
                activeName === 'description' ? currentRouteName : activeName,
                true
              )
            "
          >
            <template slot="header-right">
              <el-button
                type="text"
                class="tab-title"
                style="padding: 0"
                @click="
                  handleCommand(
                    'feedback',
                    courseInfo.course_name,
                    courseInfo.is_plan !== 1 ? '课程' : '课程模板',
                    { id: course_id }
                  )
                "
              >
                <div style="color: #1e1e1e; font-size: 14px">
                  <i class="el-icon-edit-outline"></i>
                  反馈
                </div>
              </el-button>
            </template>
            <template slot="default" slot-scope="{ name }">
              <template v-if="name === 'description'">
                <div
                  class="course-description"
                  v-html="courseInfo.description"
                  v-if="
                    $jquery('<span>' + courseInfo.description + '</span>')
                      .text()
                      .trim().length > 0
                  "
                ></div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  v-else
                >
                  暂无
                </div>
              </template>
              <!-- <router-view
                @getData="getData"
                v-bind:isComment="
                  (courseInfo.isTeach || courseInfo.isStudent) === true
                "
                v-bind:attrCallback="
                  ($event) => {
                    currentInfo = $event || {};
                  }
                "
                v-else
              ></router-view> -->
            </template>
          </el-route-tabs>
          <!-- </el-col> -->
          <!-- </el-row> -->

          <!-- </el-row> -->
        </div>
        <div style="width: 36%; margin-left: 20px">
          <Menu
            @getData="getData"
            v-bind:isComment="
              (courseInfo.isTeach || courseInfo.isStudent) === true
            "
            v-bind:attrCallback="
              ($event) => {
                currentInfo = $event || {};
              }
            "
          ></Menu>
        </div>
        <!-- <router-view
          @getData="getData"
          v-bind:isComment="
            (courseInfo.isTeach || courseInfo.isStudent) === true
          "
          v-bind:attrCallback="
            ($event) => {
              currentInfo = $event || {};
            }
          "
        ></router-view> -->
      </div>
    </div>

    <diy-dialog
      ref="dialog"
      :title="gForm.gtype === 'apply' ? '申请优惠' : '购买'"
      width="650px"
      center
      v-if="courseInfo.is_plan === 1"
    >
      <form-create
        ref="gForm"
        v-model="gForm"
        label-width="120px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'buy_type',
              type: 'radio',
              label: '购买方式',
              defaultValue: 0,
              data: [
                {
                  label: '普通许可使用',
                  value: 1,
                  disabled: (courseInfo.up_types || []).indexOf('1') === -1,
                },
                {
                  label: '高级许可使用',
                  value: 2,
                  disabled: (courseInfo.up_types || []).indexOf('2') === -1,
                },
              ],
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'price',
              type: 'number',
              label: '价格',
              defaultValue: courseInfo.price,
              disabled: gForm.gtype !== 'apply',
              step: 0.01,
              min: 0,
              max: courseInfo.price,
            },
            slots: { after: { name: 'span', domProps: { innerHTML: '元' } } },
            condition: '{{buy_type}} == 1',
          },
          {
            name: 'form-item',
            props: {
              prop: 'price',
              type: 'number',
              label: '价格',
              disabled: gForm.gtype !== 'apply',
              step: 0.01,
              min: 0,
              max: courseInfo.price2,
            },
            slots: { after: { name: 'span', domProps: { innerHTML: '元' } } },
            condition: '{{buy_type}} == 2',
          },
          {
            name: 'form-item',
            props: {
              prop: 'reason',
              label: '申请理由',
              type: 'textarea',
              showWordLimit: true,
              rows: 5,
              maxlength: 300,
            },
            style: { fontFamily: 'auto' },
            hidden: gForm.gtype !== 'apply',
          },
        ]"
      >
      </form-create>
      <div slot="footer">
        <el-button
          type="primary"
          style="margin-right: 50px"
          @click="$refs.dialog.visibled(false)"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="
            $refs.gForm.validate((form, valid) => {
              valid &&
                handleCommand('buy', { ...form, gtype: gForm.gtype }, 24);
            })
          "
          >确 定</el-button
        >
      </div>
    </diy-dialog>
    <diy-dialog
      ref="dialog"
      :title="gForm.gtype === 'apply' ? '申请优惠' : '开通课程'"
      width="650px"
      center
      v-else
    >
      <form-create
        ref="gForm"
        v-model="gForm"
        label-width="120px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'active_price',
              type: 'number',
              label: '开通课程基础费用',
            },
            slots: {
              default: () => {
                return gForm.active_price + '元';
              },
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'price',
              type: 'number',
              defaultValue: gForm.active_price,
              label: '申请优惠为',
              disabled: gForm.gtype !== 'apply',
              step: 0.01,
              min: 0,
              max: gForm.active_price,
            },
            slots: { after: { name: 'span', domProps: { innerHTML: '元' } } },
          },
          {
            name: 'form-item',
            props: {
              prop: 'reason',
              label: '申请理由',
              required: true,
              type: 'textarea',
              showWordLimit: true,
              rows: 5,
              maxlength: 300,
            },
            hidden: gForm.gtype !== 'apply',
          },
        ]"
      >
      </form-create>
      <div slot="footer">
        <!--        <el-button type="primary" style="margin-right: 50px;" @click="$refs.dialog.visibled(false)">取 消</el-button>-->
        <el-button
          type="warning"
          style="margin-right: 50px"
          @click="
            handleCommand('buy', { price: gForm.active_price, gtype: null }, 24)
          "
          >直接付款</el-button
        >
        <el-button
          type="primary"
          @click="
            $refs.gForm.validate((form, valid) => {
              valid &&
                handleCommand('buy', { ...form, gtype: gForm.gtype }, 24);
            })
          "
          >确定申请优惠</el-button
        >
      </div>
    </diy-dialog>
    <diy-dialog ref="activeDialog" :title="'激活课程申请'" width="650px" center>
      <form-create
        ref="activeForm"
        style="text-align: left"
        label-width="100px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'lecturer_name',
              label: '选择老师',
              isShowText: true,
            },
            slots: {
              default: () => {
                return courseInfo.lecturer_name || courseInfo.teach_name;
              },
            },
          },
          {
            name: 'form-item',
            props: { prop: 'price', label: '激活金额', isShowText: true },
            slots: {
              default: (v, c, h) => {
                return h('span', {
                  domProps: {
                    innerHTML:
                      '¥' +
                      (courseInfo.active_price === undefined
                        ? courseInfo.price || 0
                        : courseInfo.active_price),
                  },
                  style: 'color:#ec1919;',
                });
              },
            },
          },
          {
            name: 'form-item',
            props: { prop: 'remark', label: '备注', type: 'textarea' },
          },
        ]"
      >
      </form-create>
      <div slot="footer">
        <div>
          <el-button
            type="primary"
            style="margin-right: 50px"
            @click="$refs.activeDialog.visibled(false)"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="
              $refs.activeForm.validate((form, valid) => {
                if (valid) {
                  $http
                    .post('/xapi/course.course/activeUp', {
                      course_id,
                      price:
                        courseInfo.active_price === undefined
                          ? courseInfo.price || 0
                          : courseInfo.active_price,
                      lecturer_name:
                        courseInfo.lecturer_name || courseInfo.teach_name,
                      ...form,
                    })
                    .then(({ msg, data }) => {
                      // getDetail();
                      getNochapterDetails();
                      $handleMessage(msg || '激活课程申请成功！', 'success');
                      $refs.activeFreeDialog.visibled(false);
                    });
                }
              })
            "
            >确 定</el-button
          >
        </div>
        <div style="text-align: right">
          <el-button
            type="text"
            @click="
              $refs.activeDialog.visibled(false);
              $refs.activeFreeDialog.visibled(true);
            "
            >免费激活</el-button
          >
        </div>
      </div>
    </diy-dialog>
    <diy-dialog
      ref="activeFreeDialog"
      :title="'激活课程'"
      width="650px"
      top="25vh"
      center
    >
      <form-create
        ref="activeFreeForm"
        style="text-align: left"
        label-width="100px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'active_free_code',
              required: true,
              label: '课程激活码',
            },
          },
        ]"
      >
      </form-create>
      <div slot="footer">
        <el-button
          type="primary"
          style="margin-right: 50px"
          @click="$refs.activeFreeDialog.visibled(false)"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="
            $refs.activeFreeForm.validate((form, valid) => {
              if (valid) {
                $http
                  .post('/xapi/course.course/activeFreeUp', {
                    course_id,
                    ...form,
                  })
                  .then(({ msg, data }) => {
                    // getDetail();
                    getNochapterDetails();
                    $handleMessage(msg || '课程激活成功！', 'success');
                    $refs.activeFreeDialog.visibled(false);
                  });
              }
            })
          "
          >确 定</el-button
        >
      </div>
    </diy-dialog>
    <import-job ref="ImportJob" :course-id="course_id"></import-job>
  </div>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer/index.vue";
import Recommend from "@/views/course/components/Recommend";
import Menu from "@/views/api/teach_plan/course/c/menu.vue";
import ElRouteTabs from "@/components/com/el/elRouteTabs";
import ImportJob from "./c/ImportJob";
import ElPdf from "@/components/com/el/elPdf";
export default {
  components: { ElPdf, ImportJob, ElRouteTabs, Recommend, VideoPlayer, Menu },
  props: ["course_id"],
  data() {
    return {
      courseInfo: {},
      activeName: "description",
      tabList: [],
      gForm: {},
      currentInfo: {},
      studyLoading: false, //报名loadning
    };
  },
  watch: {
    "gForm.buy_type": {
      handler(v, oldName) {
        this.gForm.price =
          v == 1 ? this.courseInfo.price : this.courseInfo.price2;
      },
      immediate: true,
    },
  },
  created() {
    console.log(this.userInfo,"this.userInfo0000000")
    this.getNochapterDetails();
  },
  methods: {
    routeUpdate() {
      this.tabList = this.$router
        .getRoutes()
        .filter(
          (d) =>
            (d.parent || {}).name === this.currentRouteName &&
            (d.meta || {}).hidden !== true
        )
        .map((d) => {
          d.label = (d.meta || {}).title || d.name;
          return d;
        });
      console.log(this.tabList, "this.tabList");
      // this.getDetail();
      // this.getNochapterDetails()
      // console.log(this.currentInfo,"currentInfocurrentInfo")
    },
    routeChange({ routerViewDepth }) {
      // console.log(routerViewDepth,"routerViewDepthrouterViewDepth");
      this.activeName =
        (this.$route.matched[routerViewDepth + 1] || {}).name || "description";
      console.log(this.activeName, "this.activeName");
    },
    getData(e) {
      console.log(e, "传过来的数据");
      this.$jquery(this.$el).parent().scrollTop(0);
    },
    // 去课程下作业
    goWork(){
      this.$handleRoute({ course_id: this.course_id },'/match/myExamList')
    },
    // 去报名管理
    goApplyManage(){
      this.$handleRoute({ course_id: this.course_id },'teachingCourseStudy')
    },
    // 去出勤
    goAttendance(){
      // this.$handleRoute('/teaching/attendance/list')
      this.$handleRoute({ team_id: this.courseInfo.group_id,activeName:'attendance',course_id:this.course_id }, 'TeamInfo')
    },
    // 去学情
    goStuSituation(){
      this.$handleRoute({ team_id: this.courseInfo.group_id,activeName:'now',course_id:this.course_id }, 'TeamInfo')
    },
    // 去进度
    goSchedule(){
      this.$handleRoute('courseDetailStudent')
      // this.$handleRoute({ team_id: 92,activeName:'now',course_id:this.course_id }, 'TeamInfo')
    },
    // 去团队主页
    goHome(){
      this.$handleRoute({ team_id:  this.courseInfo.group_id }, "TeamInfo");
      // this.$handleRoute({ team_id: 92,activeName:'now',course_id:this.course_id }, 'TeamInfo')
    },
    // 不带章节课程详情
    getNochapterDetails() {
      this.loading = true;
      this.$http
        .post("/xapi/course.course/detail_simplen", {
          ...this.$route.params,
          id: this.course_id,
          page: 1,
          pageSize: 999,
        })
        .then(({ data }) => {
          this.loading = false;
          this.courseInfo = data;
          console.log(this.courseInfo, "this.courseInfothis.courseInfo");
          if (
            data.is_plan == 1 &&
            this.$route.name.indexOf("teachPlanCourse") !== 0
          ) {
            // return this.$handleRoute({...this.$route.query,...this.$route.params},"teachPlanCourseDetail")
          } else if (
            data.is_plan != 1 &&
            this.$route.name.indexOf("teachPlanCourse") === 0
          ) {
            // return this.$handleRoute({...this.$route.query,...this.$route.params},"courseDetail")
          }
          this.handleRouteCallback("");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDetail() {
      this.loading = true;
      this.$http
        .post("/xapi/course.course/detail", {
          ...this.$route.params,
          id: this.course_id,
          page: 1,
          pageSize: 999,
        })
        .then(({ data }) => {
          this.loading = false;

          this.courseInfo = data;
          console.log(this.courseInfo, "this.courseInfothis.courseInfo");
          //  let a= this.courseInfo.up_types.filter(v=>{
          //     return this.courseInfo['isExist' + v]!==true
          //     console.log(this.courseInfo['isExist' + v] !== true,"vvvvvvvvv");
          //     // courseInfo['isExist' + d] !== true
          //   })
          //   console.log(a,"aaaaaaaaaa");
          // console.log(this.courseInfo.up_types.filter(v=>{
          //   // return this.courseInfo['isExist' + v]!=true
          //   console.log(this.courseInfo['isExist' + v] !== true,"vvvvvvvvv");
          //   // courseInfo['isExist' + d] !== true
          // }));
          if (
            data.is_plan == 1 &&
            this.$route.name.indexOf("teachPlanCourse") !== 0
          ) {
            // return this.$handleRoute({...this.$route.query,...this.$route.params},"teachPlanCourseDetail")
          } else if (
            data.is_plan != 1 &&
            this.$route.name.indexOf("teachPlanCourse") === 0
          ) {
            // return this.$handleRoute({...this.$route.query,...this.$route.params},"courseDetail")
          }
          this.handleRouteCallback("");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    teachPlanCourseBuy(t) {
      console.log("购买");
      // return
      const courseInfo = this.courseInfo;
      var isPower = this.$isPowers("teachPlanCourseBuy");
      if (t === true) return isPower;
      if (!isPower) {
        return null;
      }
      if ((courseInfo.order || {}).id > 0) {
        return null;
      }
      if (courseInfo.isExist) {
        return null;
      }
      this.$refs.dialog.visibled(
        (this.gForm = {
          price:
            courseInfo.up_types[0] == 1
              ? courseInfo.price
              : courseInfo.price2 || 0,
          buy_type: courseInfo.up_types[0],
        }) && true
      );
    },
    teachPlanCourseBuy1() {
      const courseInfo = this.courseInfo;
      // var isPower = this.$isPowers("teachPlanCourseBuy");
      // if (t === true) return isPower;
      // if (!isPower) {
      //   return null;
      // }
      // if ((courseInfo.order || {}).id > 0) {
      //   return null;
      // }
      // if (courseInfo.isExist) {
      //   return null;
      // }
      this.$refs.dialog.visibled(
        (this.gForm = {
          price:
            courseInfo.up_types[0] == 1
              ? courseInfo.price
              : courseInfo.price2 || 0,
          buy_type: courseInfo.up_types[0],
        }) && true
      );
    },
    teachPlanCourseApplyBuy(t) {
      const courseInfo = this.courseInfo;
      // console.log(courseInfo, "courseInfocourseInfocourseInfo");
      var isPower =
        this.$isPowers("teachPlanCourseApplyBuy") &&
        (courseInfo.price > 0 || courseInfo.price2 > 0);
      if (t === true) return isPower;
      if (!isPower) {
        return null;
      }
      if ((courseInfo.order || {}).id > 0) {
        return null;
      }
      this.$refs.dialog.visibled(
        (this.gForm = {
          gtype: "apply",
          price:
            courseInfo.up_types[0] == 1
              ? courseInfo.price
              : courseInfo.price2 || 0,
          buy_type: courseInfo.up_types[0],
        }) && true
      );
    },
    handleCommandStudy() {
      if (!this.courseInfo.hasChildren) {
        return this.$message.warning("还没有可阅读的内容");
      }
      this.$handleRoute({ id: this.course_id }, "/course/study");
    },
    handleCommandActive() {},
    // handleCommandCollect(){
    //     console.log('收藏成功');
    // },
    handleCommandBuy(price, type) {
      // console.log(price,"price");
      // console.log(type,"typetype");
      var obj = typeof price === "object" ? { ...price } : { price };
      console.log(obj, "objobj");

      if (this.courseInfo.is_plan === 1) {
        this.$http
          .post("/xapi/course.course/teachPlanBuy", {
            course_id: this.course_id,
            ...obj,
          })
          .then(({ msg, data, code }) => {
            console.log(data, "daaaaaaaa");
            if (data.is_pay) {
              this.$handleMessage("模板购买成功！", "success");
              // this.getDetail();
              this.getNochapterDetails();

              this.$refs.dialog.visibled(false);
            } else if (obj.gtype) {
              this.$handleMessage("您的优惠申请已提交成功", "success", () => {
                // this.$handleRoute(
                //   { order_id: data.orderId },
                //   "teachPlanCourseDetailOrderSure"
                // );
                // this.getDetail();
                this.getNochapterDetails();

                this.$refs.dialog.visibled(false);
              });
            } else {
              this.$handleMessage(msg, "success", () => {
                this.$handleRoute(
                  { order_id: data.orderId },
                  "teachPlanCourseDetailOrderSure"
                );
              });
            }
          });
      } else if (type === 23 || type === 24) {
        this.$http
          .post("/xapi/course.course/teachActiveBuy", {
            course_id: this.course_id,
            ...obj,
          })
          .then(({ msg, data, code }) => {
            if (data.is_pay) {
              this.$handleMessage("开通成功！", "success");
              // this.getDetail();
              this.getNochapterDetails();

              this.$refs.dialog.visibled(false);
            } else if (code === 200) {
              this.$handleMessage(msg, "success", () => {
                this.$handleRoute(
                  { order_id: data.orderId },
                  "teachingCourseDetailOrderSure"
                );
              });
            }
          });
      } else {
        if (this.courseInfo.enroll_status === -1) {
          return this.$message.error("报名时间已过~~~");
        }
        if (this.courseInfo.enroll_status === 0) {
          return this.$message.error("报名还未到~~~");
        }
        this.studyLoading = true;

        this.$http
          .post("/xapi/course.course/teachBuy", {
            course_id: this.course_id,
            ...obj,
          })
          .then(({ msg, data, code }) => {
            if (data.is_pay) {
              this.$handleMessage("报名成功！", "success");
              // this.getDetail();
              this.studyLoading = false;
              this.getNochapterDetails();

              this.$refs.dialog.visibled(false);
            } else if (code === 200) {
              this.studyLoading = false;

              this.$handleMessage(msg, "success", () => {
                this.$handleRoute(
                  { order_id: data.orderId },
                  "courseDetailOrderSure"
                );
              });
            }
          }).catch((err)=>{
            console.log(err,"errerr")
            this.studyLoading = false;

          });
      }
    },
    handleCommandCancel() {
      this.$http
        .post("/xapi/com.order/cancel", {
          course_id: this.course_id,
          id: this.courseInfo.order.id,
        })
        .then(({ msg }) => {
          this.$handleMessage(msg, "success", () => {
            // this.getDetail();
            this.getNochapterDetails();
          });
        });
    },
    handleCommandCourseWhite(item, status = 1, cb) {
      this.$http
        .post("/xapi/course.course/courseWhiteVerify", {
          ...item,
          id: item.apply_log_id,
          status: status,
          reason: item.reason || (status === 1 ? "同意" : ""),
        })
        .then((result) => {
          this.$message.success(status === 1 ? "您已成功加入本门课程的学习！" : "您已拒绝加入本门课程的学习！");
          this.routeUpdate();
          cb && cb();
        })
        .catch((err) => {});
    },
    handleCommandCourseBaseUser(item, status = 1, cb) {
      this.$http
        .post("/xapi/course.course/courseBaseUserVerify", {
          ...item,
          id: item.apply_log_id,
          status: status,
          reason: item.reason || (status === 1 ? "同意" : ""),
        })
        .then((result) => {
          this.$message.success(status === 1 ? "您已成功加入本门课程的学习！" : "您已拒绝加入本门课程的学习！");
          this.routeUpdate();
          cb && cb();
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="less">
::v-deep {
  .tab-title,
  .el-tabs--top.el-tabs--border-card > .el-tabs__header .el-tabs__item {
    height: 40px;
    line-height: 40px;
  }
  .el-textarea__inner {
    font-family: auto !important;
  }
  .recommend .el-card__header {
    color: #00957e;
    padding: 16px 0 10px;
    margin: 0 16px;
    border-bottom: 1px solid #979797;
  }
  .recommend .el-card__body {
    padding: 16px;
    > .teacher-content:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  .box-card-green.el-card {
    background-color: transparent;
    box-shadow: none;
    border: none;
    border-radius: 0;
  }
  .box-card-green .el-card__header {
    background-color: #00957e;
    color: #fff;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius: 4px;
  }
  .box-card-green .el-card__body {
    background-color: transparent;
  }
  .student-item {
    text-align: center;
    margin-bottom: 16px;
    .name {
      font-size: 12px;
      color: #131313;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
::v-deep.course-description{
  p{
    span{
      text-wrap: wrap!important;
    }
    // text-wrap: wrap !important;
  }
}
</style>