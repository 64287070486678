<template>
  <div class="recommend">
    <div class="title">为你推荐</div>
    <div class="line"></div>
    <div class="recommend-item" style="display: flex;" v-for="(v,i) in course_list" :key="i" @click="$handleRoute({course_id:v.id} , 'courseDetail')">
      <el-image :src="v.cover" style="width: 144px; height: 73px;margin-right: 20px;"></el-image>
      <div style="font-size:14px;width: calc(100% - 164px);line-height: 23px;">
        <div class="ellipsis font-color" :style="{height:'46px','--line-clamp':2}">{{v.course_name}}</div>
        <div class="flex-between font-color" :style="{'--color-black':'var(--color-gray)'}">
          <div>{{v.course_list_count}}条目</div>
          <div>{{v.teach_name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  courseHot
} from "@/api/course.js"
export default {
  data () {
    return {
      course_list: []
    }
  },
  created () {
    courseHot({}).then(res => {
      this.course_list = res.data
    })
  },
};
</script>

<style lang="less" scoped>
.recommend:not(:last-of-type){
  margin-bottom: 20px;
}
.recommend{
  background: #ffffff;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.08);
  border: 0px solid #979797;
  // height: 370px;
  padding: 16px;
  .title {
    color: #00957e;
    line-height: 22px;
  }
  .line {
    margin: 0.6rem 0 1rem 0;
    height: 1px;
    border-bottom: 1px solid #979797;
  }
  .recommend-item {
    margin: 0.8rem 0;
    padding-right: 6px;
    cursor: pointer;
    .msg {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #858585;
    }
    .name {
      font-size: 14px;
      // margin-bottom: 1rem;
      margin-top: 0.3rem;
      color: #131313;
      height: 46px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}
</style>