<template>
  <!-- 导入作业 -->
  <div class="dialogVisible">
    <el-dialog title="" :visible.sync="dialogVisible" width="780px" top="6vh" :before-close="handleClose" :show-close="false" >
      <div class="header">
        <span>{{state=='bs'?'选择题目':'导入作业'}}</span>
        <i class="el-icon-circle-close" @click="dialogVisible = false"></i>
      </div>
      <div class="body">
        <el-row :gutter="20" class="body-item" v-if="state!=='bs'">
          <el-col :span="3">
            <div class="">选择章节</div>
          </el-col>
          <el-col :span="21">
            <div class="select">
              <el-cascader v-model="value" :options="options" :props="{
                  expandTrigger: 'hover',
                  children: '_list',
                  label: 'name',
                  value: 'id',
                }" @change="handleChange"></el-cascader>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="body-item">
          <el-col :span="3">
            <div class="">搜索题目</div>
          </el-col>
          <el-col :span="21">
            <el-input style="width: 48%" placeholder="搜索题目名称" v-model="searchValue" @input="searchTopic" size="small"></el-input>
          </el-col>
        </el-row>

        <el-row :gutter="20" class="body-item-table">
          <el-col :span="3">
            <div class="">选择题目</div>
          </el-col>
          <el-col :span="21">
            <ProblemListTable :operation="operationTable" @selection="selection" :isClick="false" ref="ProblemListTable"> </ProblemListTable>
            <div class="pagination">
              <el-pagination background layout="prev, pager, next" :total="operationTable.total" @current-change="currentChange" :page-size="page_size" :current-page="page" :hide-on-single-page="true">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
        <div class="save-btn">
          <div class="save" @click="save" v-if="state!='bs'">保存</div>
          <div class="save" @click="determine" v-if="state=='bs'">确定</div>

        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { courseDetail, import_work } from "@/api/course.js"
import { topicList } from "@/api/topic.js"
import ProblemListTable from "@/components/ProblemListTable/ProblemListTable.vue"
export default {
  props: {
    courseId: {
      type: String,
      required: true
    },
    state: {
      type: String,
      default: () => ''
    }
  },
  components: {
    ProblemListTable,
  },
  data () {
    return {
      questionIds: [],
      dialogVisible: false,
      searchValue: "",
      value: "",
      operationTable: {
        label: "题目名称",
        isSelection: true,
        time_str: false,
        tableData: [],
        total: 0,
      },
      options: [],
      multipleSelection: [],
      page: 1,
      page_size: 9,
    }
  },
  watch: {
    dialogVisible (a) {
      if (a) {
        if (this.state != 'bs') {
          courseDetail({ id: this.courseId }).then(({ data }) => {
            this.options = data.lists
          })
        }
        this.getTopicList()
      }
    },
  },
  created () { },
  methods: {
    close(){
      this.searchValue=''
      this.$refs.ProblemListTable.clearTable()
    },
    save () {

      if (this.value.length < 1) {
        this.$message({
          message: '请选择章节',
          type: 'warning'
        })
        return
      }
      if (this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择题目',
          type: 'warning'
        })
        return
      }
      console.log(this.multipleSelection, 'value')
      let question_ids = []
      this.multipleSelection.map(v => question_ids.push(v.id))


      import_work({
        id: this.courseId,
        list_id: this.value[1],
        question_ids
      }).then(res => {
        this.$message({
          message: '导入成功',
          type: 'success'
        })
        this.dialogVisible = false
        //刷新课程详情
        this.$parent.getDetail && this.$parent.getDetail()
      })
    },
    determine () {
      this.$emit('questionIdsEvent',this.multipleSelection)
      // this.$parent.questionIds = this.multipleSelection
      this.dialogVisible = false
    },
    //选择de题目
    selection (v) {
      console.log(v, 'v')
      this.multipleSelection = v
    },
    searchTopic () {
      this.page = 1;
      this.getTopicList()
    },
    currentChange (e) {
      this.page = e
      this.getTopicList()
    },
    getTopicList () {
      topicList({
        page: this.page,
        page_size: this.page_size,
        title: this.searchValue,
      }).then((res) => {
        this.operationTable.total = res.data.total
        this.operationTable.tableData = (res.data || {}).data
      })
    },
    handleClose () { },
    handleChange () { },
    handleSelectionChange () { },
  },
};
</script>

<style lang="less" scoped>
.dialogVisible {
  .pagination {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
  }
  ::v-deep {
    .el-cascader-menu__list {
      color: #00957e;
    }
    .el-cascader-node__label {
      color: #00957e !important;
    }
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-input__inner {
      // border-radius: 40px;
      border: 1px solid #d4d4d4;
      height: 30px;
      line-height: 30px;
    }
    .el-table td,
    .el-table th.is-leaf {
      border-top: 1px solid #ebeef5;
      //  border-bottom: 1px solid #EBEEF5;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #00957e;
      border-color: #00957e;
    }
    .el-cascader .el-input .el-input__inner:focus,
    .el-cascader .el-input.is-focus .el-input__inner {
      border-color: #00957e;
    }
  }
  .header {
    height: 35px;
    background: #00957e;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    line-height: 35px;
    align-items: center;
    .el-icon-circle-close {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .body {
    padding: 0 3rem 2rem 3rem;
    .body-item-table {
      margin-top: 1.5rem;
    }
    .body-item {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      .select {
        display: flex;
        justify-content: space-between;
        > div {
          width: 48%;
        }
      }
    }
    .save-btn {
      height: 2rem;
      margin-top: 1rem;
    }
    .save {
      float: right;
      bottom: 2rem;
      right: 2rem;
      width: 40px;
      cursor: pointer;
      background: #00957e;
      border-radius: 2px;
      color: #fff;
      padding: 6px 16px;
      text-align: center;
    }
  }
}
</style>