<template>
  <div class="catalogue" v-loading="loading">
    <h3>目录</h3>
    <!-- <el-collapse class="catalogue-item" accordion v-if="courseInfo.lists && courseInfo.lists.data.length > 0"  @change="handleChange"> -->
    <el-collapse class="catalogue-item" accordion v-if="lists && lists.length > 0"  @change="handleChange">
      <el-collapse-item v-for="(v, i) in lists" :key="i" :name="v.id">
        <h3 slot="title" :style="{color:v._list.map(d=>d.id).indexOf(jInfo.id) > -1 ? 'var(--color-primary)':null}">{{v.title}}</h3>
                 
        <div style="padding: 0 30px;"  v-loading="entryLoading">
          <div class="course-menu-item" v-for="(j, k) in v._list" :key="k" >
<!--          <el-collapse class="catalogue-item">-->
<!--              <el-collapse-item v-for="(j, k) in v._list" :key="k">-->
                <div slot="title" style="width: 100%" >
                  <div style="display: flex;justify-content: space-between;" class="catalogue-details-title">
                    <!-- <div @click="$handleRoute({...$route.params , list_id:j.id} , (priexRoute||'teachCourse')+'DetailMenu',null , true)"
                         :style="{color:j.id===jInfo.id ? 'var(--color-primary)':null}"
                    >{{j.number + '、' + j.title}}</div> -->
                    <div @click="handleCommand('study')" :style="{color:j.id===jInfo.id ? 'var(--color-primary)':null}">{{ j.name}}</div>
                    <div style="display: flex;" v-if="(j.video && j.duration)||j.files">
                      <template v-if="j.video && j.duration">
                        <img :src="require('@/assets/images/course/timer.png')" style="width: 18px; height: 18px;margin-top: 2px;" />
                        <span>{{ j.duration }}</span>
                      </template>
                      <template v-if="isCat">
                        <el-button @click="$callback({...j,isPdf:false})" type="primary" size="mini" style="margin-left: 10px;padding: 5px;" v-if="j.video">
                          查看视频
                        </el-button>
                        <el-button @click="$callback({...j,pdf:j.files,isPdf:true,pdfCurrent:1})" type="primary" size="mini" style="margin-left: 10px;padding: 5px;" v-if="j.files">
                          查看文档
                        </el-button>
                      </template>
                      <template v-else-if="courseInfo.is_plan+'' === '0' && courseInfo.isStudent">>
                        <el-button @click="$handleRoute({id:courseInfo.id,list_id:j.id},'/course/study')" type="primary" size="mini">
                          立即学习
                        </el-button>
                      </template>
                      <template v-else-if="!j.is_free">
                        <el-button @click="$callback({...j,isPdf:false})" type="primary" size="mini" style="margin-left: 10px;padding: 5px;" v-if="j.video">
                          试看
                        </el-button>
                        <el-button @click="$callback({...j,pdf:j.files,isPdf:true,pdfCurrent:1})" type="primary" size="mini" style="margin-left: 10px;padding: 5px;" v-if="j.files">
                          试读
                        </el-button>
                      </template>
                    </div>
                  </div>
                </div>
<!--                <div style="padding: 20px 15px; border-radius: 15px; border: 1px solid #979797;position: relative;">-->
<!--                  <div v-html="j.desc" v-if="$jquery('<span>'+j.desc+'</span>').text().trim().length > 0"></div>-->
<!--                  <div v-else>暂无简介</div>-->
<!--                  <div style="position: absolute;right:20px;bottom:15px;" v-if="courseInfo.is_plan !== 1 && j.task_id > 0 && !courseInfo.isTeach">-->
<!--                    <el-button size="mini" type="primary" @click="$handleRoute({id:j.task_id,type:'作业'},'/match/questAnswer')">作业</el-button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </el-collapse-item>-->
<!--          </el-collapse>-->
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-empty :image="require('@/assets/images/course/none.png')" description="暂无数据" v-else></el-empty>
  </div>
</template>

<script>

export default {
  props:['priexRoute'],
  data(){
    return {
      courseInfo:{},
      jInfo:{},
      isCat:false,
      loading:false,
      entryLoading:false,//条目
      lists:[],//章节信息
      itemId:'0'
    }
  },
  methods:{
     handleCommandStudy() {
      // if (!(this.courseInfo.lists && this.courseInfo.lists.data).length) {
      if (!(this.lists).length) {
        return this.$message.warning("还没有可阅读的内容");
      }
      //  console.log( this.courseInfo.id," this.courseInfo.id");
      //  course_id:"771"
      //  list_id:1367
      // return
      // this.$handleRoute({ id: this.courseInfo.id }, "/course/study");
    },
    // 点击章节获取条目
    handleChange(e){
      // console.log(e,"eeeeeeeeeeee")
      if(e){
      this.entryLoading=true
      this.$http.post('/xapi/course.course/getCourseListList' ,
        {   
        course_id: this.$route.params.course_id,
        unit_id: e
        }).then(({data})=>{
            this.entryLoading=false
            // console.log(data,"条目信息")
            this.lists.forEach(v=>{
              data.forEach(vv=>{
                // 判断点开的章节与列表章节id是否一致
                if(v.id==vv.unit_id){
                  v._list=data
                }
              })
            })
            // console.log( this.lists," this.lists this.lists~~~~~~~")
      }).finally(()=>{
        this.loading = false;
      });
    }

    },
    userInfoListener(v){
      var data = this.courseInfo;
      this.isCat = (data.is_plan == 1 && (data.isExist||data.isExist2||data.isExist1)) || data.user_id+'' === v.id+'';
    },
    $callback(info){
      this.jInfo = info;
      console.log(this.jInfo,"this.jInfothis.jInfo");
      this.$emit('getData',"111")
      // console.log(document.documentElement.scrollTop,"document.documentElement.scrollTop");
      // document.documentElement.scrollTop = 0;
      typeof this.$attrs.attrCallback === 'function' && this.$attrs.attrCallback(...arguments);
    },
    routeUpdate(){
      // this.getDetail();
      this.getChapterDetail()
    },
    // 获取章节列表
    getChapterDetail(){
      this.loading = true;
      this.$http.post('/xapi/course.course/getUnitList' ,
          {   
          ...this.$route.params,
          id:this.$route.params.course_id,
          page: 1,
          pageSize: 999,}).then(({data})=>{
            console.log(data,"章节信息")
        this.lists = data.data;
            console.log(this.lists,"this.liststhis.lists")
      }).finally(()=>{
        this.loading = false;
      });
    },
    getDetail(){
      this.loading = true;
      this.$http.post('/xapi/course.course/detail' ,
          {   
          ...this.$route.params,
          id:this.$route.params.course_id,
          page: 1,
          pageSize: 999,}).then(({data})=>{
        this.courseInfo = data;
        this.isCat = (data.is_plan == 1 && (data.isExist||data.isExist2||data.isExist1)) || data.user_id+'' === this.userInfo.id+'';
      }).finally(()=>{
        this.loading = false;
      });
    },
  }
}
</script>

<style scoped lang="less">
::v-deep{
  .el-collapse,.el-collapse-item__header,.el-collapse-item__wrap{
    border: none;
  }
  .course-menu-item + .course-menu-item{
    margin-top: 20px;
  }
}
.catalogue{
  background-color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
}
</style>